import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import styled, { css } from 'styled-components';
import { useState } from 'react';

import type { DateTime, DateTimeFormats } from 'util/DateTime';
import { isValidDate } from 'util/DateTime';
import TimeRangeSelectInput from 'data-warehouse/components/TimeRangeSelectInput';
import useDataWarehouseEstimate from 'data-warehouse/hooks/useDataWarehouseEstimate';
import ArchiveSizeEstimate from 'data-warehouse/components/ArchiveSizeEstimate';
import { Modal, Input } from 'components/bootstrap';
import { FormikInput, ModalSubmit, Select } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import TimeRangeDisplay from 'views/components/searchbar/time-range-filter/TimeRangeDisplay';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import StreamSelectField from 'data-warehouse/components/StreamSelectField';
import DataWarehouseRetrievalFiltersField from 'data-warehouse/components/DataWarehouseRetrievalFiltersField';
import { BOTH_INCLUSION_TYPE, DATA_WAREHOUSE_INCLUSION_TYPE, INDEXER_INCLUSION_TYPE } from 'data-warehouse/Constants';
import type { DataWarehouseActionFormValues, DataWarehouseActionValues, EstimateParams, InclusionType } from 'data-warehouse/Types';

type Props = {
  modalTitle: string,
  defaultTimeRange?: AbsoluteTimeRange,
  show: boolean,
  onHide: () => void
  submitButtonText: string,
  children: React.ReactNode,
  onSubmit: (values: DataWarehouseActionValues) => void,
  hourOnly?: boolean,
  streamIds: Array<string>,
  type: 'retrieval' | 'delete',
  availableStreams: Array<{ key: string, value: string }>,
};
type FormatTime =(time: DateTime, format?: DateTimeFormats) => string;

const StyledModal = styled(Modal)`
  z-index: 1070;
`;

const StyledP = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;

const FullDelete = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.lg};
  margin-top: ${theme.spacings.lg};
`);

const INCLUSION_TYPE_OPTIONS = [
  { value: DATA_WAREHOUSE_INCLUSION_TYPE, label: 'Must include: Search Cluster' },
  { value: INDEXER_INCLUSION_TYPE, label: 'Must exclude: Search Cluster' },
  { value: BOTH_INCLUSION_TYPE, label: 'Include All' },
];

const getDefaultValues = (formatTime: FormatTime, isDeleteAction: boolean, timeRange?: AbsoluteTimeRange, streamIds?: Array<string>) => {
  const from = timeRange?.from || new Date().setMinutes(0, 0);
  const to = timeRange?.to || new Date().setMinutes(0, 0);

  return {
    ...(!isDeleteAction && { inclusionType: BOTH_INCLUSION_TYPE as InclusionType }),
    streamIds,
    filterFields: [],
    nextTimeRange: {
      type: 'absolute' as const,
      from: formatTime(from),
      to: formatTime(to),
    },
  };
};

const DataWarehouseActionModalForm = ({
  modalTitle,
  defaultTimeRange = null,
  show,
  onSubmit,
  onHide,
  children,
  submitButtonText,
  hourOnly = false,
  streamIds,
  type,
  availableStreams,
}: Props) => {
  const [estimateParam, setEstimateParam] = useState<EstimateParams>({
    from: defaultTimeRange?.from || '',
    to: defaultTimeRange?.to || '',
    streamIds: streamIds || undefined,
    inclusionType: undefined,
    fieldFilters: [],
  });
  const {
    data: estimate,
  } = useDataWarehouseEstimate(estimateParam);

  const { formatTime } = useUserDateTime();
  const formatError = 'Format must be: YYYY-MM-DD [HH:mm].';
  const rangeError = 'The "To" date must come after the "From" date.';

  const isDeleteAction = type === 'delete';

  const validate = (values: DataWarehouseActionFormValues) => {
    const { nextTimeRange: { to, from }, fullDelete, inclusionType, streamIds: streamIdList, fieldFilters = [] } = values;
    setEstimateParam({ streamIds: streamIdList, from, to, inclusionType, fieldFilters });
    let errors: {
      nextTimeRange?:{
        from?: string,
        to?: string,
      }
      inclusionType?: string,
    } = {};

    if (!isDeleteAction && !inclusionType) {
      errors = { ...errors, inclusionType: 'The Include Message field is required.' };
    }

    if (!fullDelete && !from) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, from: 'The "From" field is required.' } };
    }

    if (!fullDelete && !to) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, to: 'The "To" field is required.' } };
    }

    if (!fullDelete && from && !isValidDate(from)) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, from: formatError } };
    }

    if (!fullDelete && to && !isValidDate(to)) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, to: formatError } };
    }

    if (!fullDelete && from >= to) {
      errors = { ...errors, nextTimeRange: { ...errors.nextTimeRange, to: rangeError } };
    }

    return errors;
  };

  const handleSubmit = ({ nextTimeRange: { from, to }, fullDelete, wipeRestores, inclusionType, streamIds: streamIdList, fieldFilters }: DataWarehouseActionFormValues) => {
    onSubmit({ from, to, full_delete: fullDelete, wipe_restores: wipeRestores, inclusion_type: inclusionType, stream_ids: streamIdList, field_filters: fieldFilters });
  };

  return (
    <StyledModal title={modalTitle}
                 onHide={() => onHide()}
                 show={show}>

      <Formik initialValues={{
        ...getDefaultValues(formatTime, isDeleteAction, defaultTimeRange, streamIds),
      }}
              onSubmit={handleSubmit}
              validate={validate}>
        {({ isSubmitting, isValidating, isValid, setFieldValue, values: { fullDelete, nextTimeRange, inclusionType }, errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children}

              {isDeleteAction && (
              <FullDelete>
                <Input id="fullDelete"
                       label="Full Delete:">
                  <FormikInput label="Delete all data in the Data Warehouse"
                               name="fullDelete"
                               id="fullDelete"
                               type="checkbox" />
                </Input>
                {fullDelete && (
                <Input id="wipe_restores"
                       label="Wipe retrievals:">
                  <FormikInput label="Wipe all retrievals"
                               name="wipeRestores"
                               id="wipeRestores"
                               disabled={!fullDelete}
                               type="checkbox" />
                </Input>
                )}
              </FullDelete>
              )}
              {!isDeleteAction && (
                <StreamSelectField value={streamIds}
                                   disabled={false}
                                   streams={availableStreams}
                                   onChange={(value) => setFieldValue('streamIds', value)} />
              )}
              {!fullDelete && (
              <>
                <StyledP>Time Range: </StyledP>
                <p>Please select a time range and click {type}.</p>
                <TimeRangeDisplay timerange={nextTimeRange} />
                <TimeRangeSelectInput timerange={nextTimeRange}
                                      disableTimeMinute={hourOnly}
                                      disableTimeSecond={hourOnly} />
              </>
              )}
              {!isDeleteAction && (
              <Field name="inclusion_type">
                {() => (
                  <Input help="Type of message to be retreive"
                         id="inclusion-type"
                         error={errors.inclusionType}
                         label="Filter Retrieval by Original Destination">
                    <Select id="inclusionType"
                            name="inclusionYype"
                            placeholder="Select type of retrieval filter"
                            options={INCLUSION_TYPE_OPTIONS}
                            matchProp="label"
                            onChange={(option) => {
                              setFieldValue('inclusionType', option);
                            }}
                            value={inclusionType} />
                  </Input>
                )}
              </Field>
              )}
              {!isDeleteAction && <DataWarehouseRetrievalFiltersField availableStreams={availableStreams} />}
              {(!fullDelete && isValid) && estimate && <ArchiveSizeEstimate estimate={estimate} />}
            </Modal.Body>
            <Modal.Footer>
              <ModalSubmit submitButtonText={submitButtonText}
                           submitLoadingText="Submitting request..."
                           onCancel={() => onHide()}
                           disabledSubmit={isValidating || !isValid}
                           isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </StyledModal>
  );
};

export default DataWarehouseActionModalForm;
