import { useQuery } from '@tanstack/react-query';
import URI from 'urijs';

import UserNotification from 'util/UserNotification';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_INCLUSION_TYPE } from 'data-warehouse/Constants';
import useUserDateTime from 'hooks/useUserDateTime';
import type FetchError from 'logic/errors/FetchError';
import type { EstimateParams, EstimateType } from 'data-warehouse/Types';

type Options = {
  enabled: boolean,
}

const fetchEstimate = (params: EstimateParams, userTimezone: string) => {
  const { streamIds, from, to, inclusionType = DATA_WAREHOUSE_INCLUSION_TYPE, fieldFilters } = params;

  if (from === '' || to === '' || streamIds.length === 0) {
    return null;
  }

  const uri = new URI(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.estimate().url)
    .addSearch({ stream_ids: streamIds, inclusion_type: inclusionType })
    .toString();

  return fetch('POST', qualifyUrl(`${uri}&${getDataWarehouseUriParams({ from, to }, userTimezone)}`), [...fieldFilters]);
};

const useDataWarehouseEstimate = (params: EstimateParams, { enabled }: Options = { enabled: true }): {
  data: EstimateType,
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { userTimezone } = useUserDateTime();
  const { data, refetch, isInitialLoading } = useQuery<EstimateType, FetchError>(
    ['data-warehouse', 'estimate', params],
    () => fetchEstimate(params, userTimezone),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Data Warehouse action estimate failed with status: ${errorThrown}`,
          'Could not load Data Warehouse estimate.');
      },
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
  });
};

export default useDataWarehouseEstimate;
