import { adjustFormat, toUTCFromTz } from 'util/DateTime';

export const toInternalTime = (date: string, userTimezone) => adjustFormat(toUTCFromTz(date, userTimezone), 'internal');

export const getDataWarehouseUriParams = (values: { from: string, to: string, fullDelete?: boolean, wipeRestores?: boolean }, userTimezone: string) => {
  const from = values.from ? toInternalTime(values.from, userTimezone) : '';
  const to = values.to ? toInternalTime(values.to, userTimezone) : '';

  const fullDelete = !!values.fullDelete;
  const wipeRestores = !!values.wipeRestores;

  const timeRangeUriParams = from !== '' || to !== '' ? `from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}` : '';

  const fullDeleteUriParams = fullDelete ? `full_delete=${fullDelete}&wipe_restores=${wipeRestores}` : '';

  if (fullDelete) {
    if (timeRangeUriParams.length > 0) {
      return `${timeRangeUriParams}&${fullDeleteUriParams}`;
    }

    return fullDeleteUriParams;
  }

  return timeRangeUriParams;
};
